import {EventCategory} from './event-category.class';
import {PriceItem} from './price-item.class';
import {mapSubscriptionTypePrice, SubscriptionTypePrice} from './subscription-type-price.class';
import {ModelBase} from './abstract/model-base';
import {mapResource, Resource} from './event-resource.class';
import {ExternalData} from './external-data.class';
import {mapTranslation, Translation} from './translation';
import {isNullOrUndefined} from '../common';

export class SubscriptionType extends ModelBase {
    public id: string = null;
    public status: SubscriptionTypeStatus = 'ONLINE';
    public statusDescription: any = null;
    public statusDescriptionTranslation: Translation = null;
    public isMembership: boolean = null;
    public isGuestManagerSubscription: boolean = null;
    public name: string = null;
    public nameTranslation: Translation = null;
    public venueId: string = null;
    public analyticsDashboardId: string = null;
    public pricePlanId: string = null;
    public startAt: string = null;
    public endAt: string = null;
    public saleStartAt: string = null;
    public saleEndAt: string = null;
    public validDays: string = null;
    public subscriptionTypePrices: SubscriptionTypePrice[] = [];
    public eventCategories: EventCategory[] = [];
    public prices: PriceItem[] = [];
    public externalData: ExternalData[] = [];
    public createdAt: string = null;
    public updatedAt: string = null;
    public description: string = null;
    public descriptionTranslation: any = null;
    public resource: Resource = null;

    constructor() {
        super();
        super.setRoutes({
            post: 'v1.0/subscription/type',
            getMany: 'v1.0/subscription/type',
            get: 'v1.0/subscription/type/:id',
            patch: 'v1.0/subscription/type/:id',
            delete: 'v1.0/subscription/type/:id'
        });
    }

    getSubscriptionTypePriceWithPrice(venueSectionGroupId: string): SubscriptionTypePrice {
        return this.subscriptionTypePrices.find(subscriptionTypePrice => {
            const priceItem = subscriptionTypePrice.priceItems.find(price => price.venueSectionGroupId === venueSectionGroupId);
            return !isNullOrUndefined(priceItem?.price);
        });
    }

    mapModel(data: any): SubscriptionType {
        const model = new SubscriptionType();
        model.id = data.id;
        model.status = data.status;
        model.statusDescription = data.statusDescription;
        model.statusDescriptionTranslation = data.statusDescriptionTranslation ? mapTranslation(data.statusDescriptionTranslation) : Translation.empty();
        model.isMembership = data.isMembership;
        model.isGuestManagerSubscription = data.isGuestManagerSubscription;
        model.name = data.name;
        model.nameTranslation = data.nameTranslation ? mapTranslation(data.nameTranslation) : Translation.empty();
        model.venueId = data.venueId;
        model.analyticsDashboardId = data.analyticsDashboardId;
        model.pricePlanId = data.pricePlanId;
        model.startAt = data.startAt;
        model.endAt = data.endAt;
        model.saleStartAt = data.saleStartAt;
        model.saleEndAt = data.saleEndAt;
        model.validDays = data.validDays;
        model.subscriptionTypePrices = data.subscriptionTypePrices ? data.subscriptionTypePrices.map(subscriptionTypePrice => mapSubscriptionTypePrice(subscriptionTypePrice)) : [];
        model.eventCategories = data.eventCategories;
        model.prices = data.prices;
        model.externalData = data.externalData ? data.externalData : [];
        model.createdAt = data.createdAt;
        model.updatedAt = data.updatedAt;
        model.description = data.description;
        model.descriptionTranslation = data.descriptionTranslation ? mapTranslation(data.descriptionTranslation) : Translation.empty();
        model.resource = data.resource ? mapResource(data.resource) : null;

        return model;
    }
}

export function mapSubscriptionType(item: any): SubscriptionType {
    return new SubscriptionType().mapModel(item);
}

export type SubscriptionTypeStatus = 'ONLINE' | 'SOLD_OUT';
